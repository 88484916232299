canvas {
  width: 100%;
  height: 100%;
  transition: all 500ms;
}

.fade-cookie-enter {
  opacity: 0;
  transform: scale(0.8);
}

.fade-cookie-enter-active {
  opacity: 1;
  transform: scale(1);
}

.fade-cookie-exit {
  opacity: 1;
}

.fade-cookie-exit-active {
  opacity: 0;
  transform: scale(0.8);
}
