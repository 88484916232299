@import "../../global-style/config/breakpoints/index.scss";

.content {
  --h1-font-family: bold;
  transition: opacity 500ms ease-in-out;

  &__intro {
    color: var(--main-blue);
    text-transform: uppercase;

    @media #{$desktop} {
      max-width: 350px;
    }
  }

  &__winning,
  &__loosing {
    --h1-font-size: 40rem;
    color: var(--main-blue);
    // text-transform: uppercase;
    margin-bottom: 40rem;
  }
}

.content__intro {
  transition: all 300ms ease-in-out;
}

.content__loosing {
  transition: all 300ms ease-in-out;
}

.fade-content-enter {
  opacity: 0;
}

.fade-content-enter-active {
  opacity: 0;
}

.fade-content-exit {
  opacity: 0;
}

.fade-content-exit-active {
  opacity: 0;
}
