@import "./global-style/config/index.scss";

body {
  background-color: var(--main-black);
  padding: 50rem;
  cursor: url(./assets/needle.png), default;

  @media #{$desktop} {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media #{$tablet}, #{$mobile} {
    * {
      -webkit-tap-highlight-color: transparent;
    }
  }

  &.prevent-scroll {
    overflow: hidden;
  }
}
