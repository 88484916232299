html {
  @media #{$mobile} {
    font-size: 0.5px;
  }

  @media #{$tablet} {
    font-size: 0.8px;
  }

  @media #{$desktop} {
    font-size: 1px;
  }
}

body {
  font-family: var(--text-font-family, sans-serif);

  @media #{$desktop} {
    font-size: 18px;
  }

  @media #{$mobile}, #{$tablet} {
    font-size: 16px;
  }
}

:is(h1, h2, h3, h4) {
  --titles-font-family: extra-bold, sans-serif;
}

h1 {
  font-size: var(--h1-font-size, 55rem);
  font-family: var(--h1-font-family, sans-serif);
}

h2 {
  font-size: var(--h2-font-size, 40rem);
  font-family: var(--h2-font-family, sans-serif);
}
