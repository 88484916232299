@font-face {
  font-family: "bold";
  src: url("./utils/opensanshebrew-bold-webfont.woff2") format("woff2"),
    url("./utils/opensanshebrew-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "light";
  src: url("./utils/opensanshebrew-light-webfont.woff2") format("woff2"),
    url("./utils/opensanshebrew-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "regular";
  src: url("./utils/opensanshebrew-regular-webfont.woff2") format("woff2"),
    url("./utils/opensanshebrew-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
