@import "../../global-style/config/breakpoints/index.scss";

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30rem;

  @media #{$mobile}, #{$tablet} {
    margin-bottom: 40rem;
    align-items: center;
    text-align: center;
    line-height: 1.4;
  }

  &__logo {
    display: inline-block;
    width: 270rem;
  }
}
