@import "../../global-style/config/breakpoints/index.scss";

.game {
  display: flex;
  justify-content: center;
  min-height: 600rem;

  @media #{$desktop} {
    align-items: center;
    min-width: 600px;
  }
}
