*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
  all: unset;
  display: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
}

table {
  border-collapse: collapse;
}

textarea {
  white-space: revert;
}

body {
  background-color: var(--main-black);
}

a {
  cursor: pointer;
}

a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

button {
  border: 1px solid var(--main-white);
  padding: 15rem 25rem;
  color: var(--main-white);
  font-family: regular, sans-serif;
  width: fit-content;
  transition: 0.3s all ease-in-out;

  &:hover {
    border-color: var(--main-blue);
    background-color: var(--main-blue);
    color: var(--main-black);
    cursor: pointer;
  }
}

::selection {
  background: transparent;
}
::-moz-selection {
  background: transparent;
}
