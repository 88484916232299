.rotated-word {
  color: var(--main-white);
  letter-spacing: 5px;
  perspective: 700px;

  &__letter {
    display: inline-block;
    animation: rotateLetter var(--duration) ease-in-out var(--delay) infinite
      alternate;
  }

  @keyframes rotateLetter {
    0%,
    10% {
      transform: rotateX(0deg);
    }
    90%,
    100% {
      transform: rotateX(720deg);
    }
  }
}
