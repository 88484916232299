@import "../../global-style/config/breakpoints/index.scss";

.footer {
  @media #{$mobile}, #{$tablet} {
    text-align: center;
    margin-top: 60rem;
  }

  .copyright {
    color: var(--main-medium-light-gray);
    font-family: regular, sans-serif;
    line-height: 1.5;
    font-size: 14px;

    #heart-copyright {
      color: var(--main-blue);
    }

    &__link {
      color: var(--main-white);
      position: relative;
      transition: color 0.3s ease-in-out;

      &::after {
        content: "";
        position: absolute;
        height: 1px;
        bottom: -2px;
        inset-inline: 0;
        background-color: var(--main-blue);
        transition: transform 0.3s ease-in-out;
        transform: scaleX(0);
      }

      &:hover {
        color: var(--main-blue);
      }

      &:hover::after {
        transform: scaleX(1);
      }
    }
  }
}
