.heart-loader {
  width: 350rem;
  height: 350rem;
  overflow: visible;
  transform: rotate(-45deg);

  &__heartPath {
    --painting-animation: heart-anim 4s 0s forwards;
    --pulse-animation: pulse-anim 1s 4s infinite;
    stroke: var(--main-blue);
    fill: transparent;
    stroke-dasharray: 308.522, 308.522;
    stroke-dashoffset: 308.522;
    animation: var(--painting-animation);

    &--winning {
      transform-origin: center;
      animation: var(--painting-animation), var(--pulse-animation);
    }
  }

  &__stroke {
    stroke: var(--main-black);
    stroke-dasharray: 600, 600;
    stroke-dashoffset: 600;

    animation: strokeAnim 4s 2s forwards;
  }

  @keyframes strokeAnim {
    33% {
      stroke-dashoffset: 600;
    }
    66% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes group-anim {
    43% {
      transform: rotate(0);
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes heart-anim {
    10% {
      stroke-dashoffset: 308.522;
      fill: transparent;
    }
    70% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    87% {
      stroke-dashoffset: 0;
      fill: var(--main-blue);
    }
    100% {
      stroke-dashoffset: 0;
      fill: var(--main-blue);
    }
  }

  @keyframes pulse-anim {
    20% {
      transform: scale(1.1);
      // fill: var(--main-white);
      // stroke: var(--main-white);
    }
  }
}
