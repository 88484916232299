@import "../../global-style/config/breakpoints/index.scss";

.game-screen {
  max-width: 1200px;
  margin: 0 auto;

  @media #{$mobile}, #{$tablet} {
    display: flex;
    flex-direction: column;
  }

  @media #{$desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
